import React, { useState, useEffect } from 'react';
import 'tailwindcss/tailwind.css';
import { FaCheckCircle, FaEdit, FaTimesCircle, FaTrashAlt } from 'react-icons/fa';
import axios from 'axios';
import { Select, Option } from "@material-tailwind/react"; 
import { apiBaseUrl as baseEndpoint } from '../../../APIs/endpoints';
import { toast, ToastContainer } from 'react-toastify';

const TeacherForm = (props) => {
    
    const defaultData = {
        designation: '',
        class_teacher: false,
        date_of_joining: null,
        grade: null, 
        section:null,
        id: null,
    };

    const [ formData, setFormData] = useState(() => {
        const storedinformations = localStorage.getItem('informations');
        return storedinformations ? JSON.parse(storedinformations) : defaultData;
    });
    const [errors, setErrors] = useState({});

    // const [ClassRooms, setClassRooms] = useState([]);
    const [responsibilities, setResponsibilities] = useState(() => {
      const ex_respon = localStorage.getItem('Responsibilities');
      return ex_respon ? JSON.parse(ex_respon) : []
    });
    const [newResponsibility, setNewResponsibility] = useState({
        grade: '',
        section: '',
        subject: '',
        id: null,
    });

    const [classRooms, setClassRooms] = useState([]);

useEffect(() => {
    const fetchClassRooms = async () => {
        try {
            const response = await axios.get(`${baseEndpoint}/api/classes/`);
            setClassRooms(response.data);
        } catch (error) {
            console.error('Error fetching classrooms:', error);
        }
    };

    fetchClassRooms();
}, []);
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSelectChange = (field) => (event) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: event.target.value,
        }));
    };

    const updateGrade = (val) => {
        setFormData({ ...formData, ['grade']: val })
    }


    const handleEdit = (id) => {
      const responsibilityToEdit = responsibilities.find(r => r.id === id);
      if (responsibilityToEdit) {
          setNewResponsibility(responsibilityToEdit);
          console.log('editing')
      }
  };

  const handleDelete = async (rid) => {
    try {
        console.log("Responsibility ID to delete:", rid);
        console.log("Current responsibilities:", responsibilities);

        // Remove the responsibility from the state
        const updatedResponsibilities = responsibilities.filter(
            responsibility => responsibility.id !== rid
            
        );
        setResponsibilities(updatedResponsibilities);
        console.log(rid,"sdc")
        // Send a delete request to the backend
        const deleteEndpoint = `${baseEndpoint}/api/responsibilities/${rid}/`;
        await axios.delete(deleteEndpoint);

        console.log("Responsibility deleted successfully");
    } catch (error) {
        console.error('Error deleting responsibility:', error);
    }
};

    const addResponsibility = () => {
      setResponsibilities([...responsibilities, { ...newResponsibility, id: Date.now() }]);
      const teacher = JSON.parse(localStorage.getItem('teacher'));
      newResponsibility.teacher = teacher.id;
      let endpoint = `${baseEndpoint}/api/teachers/${teacher.id}/responsibilities/`;
      let method = 'POST';
      // setNewResponsibility({ ...newResponsibility, ['teacher']: teacher })
      axios({
          method: method,
          url: endpoint,
          data: newResponsibility,
          headers: {
              "Authorization": `Bearer ${localStorage.getItem('access_token')}`
          }
         
      }).then(response => {
          console.log(response);
          console.log(response.data);
          var responsibilities_lst = JSON.parse(localStorage.getItem("Responsibilities"));
          if (responsibilities_lst == null) responsibilities_lst = [];
          
          console.log(responsibilities_lst);
          responsibilities_lst.push(response.data);
          localStorage.setItem('Responsibilities', JSON.stringify(responsibilities_lst));
          toast.success("Responsibilities Information submitted")
         
      }).catch(err => {
        const errorMessage = err.response && err.response.data 
        ? `Error: ${err.response.status} - ${err.response.statusText}\n${JSON.stringify(err.response.data, null, 2)}`
        : "An unexpected error occurred. Please try again later.";
      toast.error(errorMessage);
      })
      setNewResponsibility({ grade: '', section: '', subject: '' });

  };

  const handleReset = () => {
    setNewResponsibility({ grade: '', section: '', subject: '' });
};


    const validateForm = () => {
      let valid = true;
      const newErrors = {};
  
      const designation = String(formData.designation).trim();
      const grade = String(formData.grade).trim();
      const section = String(formData.section).trim();
      const class_teacher = typeof formData.class_teacher === 'string' 
      ? formData.class_teacher.trim() 
      : String(formData.class_teacher).trim();

  
      if (!designation) {
          newErrors.designation = "Designation is required";
          valid = false;
      }
  
      if (!class_teacher) {
        newErrors.class_teacher = "Class Teacher selection is required";
        valid = false;
    }

    // If class_teacher is true, grade and section are required
    if (class_teacher === "true") {
        if (!grade) {
            newErrors.grade = "Grade is required";
            valid = false;
        }
        if (!section) {
            newErrors.section = "Section is required";
            valid = false;
        }
    }
      setErrors(newErrors);
      return valid;
  };
  

    const handleSubmit = async (event) => {
        event.preventDefault();
    
      if (!validateForm()) {
        return; 
    }
    
        const teacher = JSON.parse(localStorage.getItem('teacher'));
        formData.teacher = teacher.id;
        let endpoint = `${baseEndpoint}/api/teachers/${teacher.id}/informations/`;
        let method = 'POST';
    
        if (formData.id) {  
            endpoint = `${baseEndpoint}/api/teachers/${teacher.id}/informations/${formData.id}/`;
            method = 'PUT';
        }
    
        try {
            const response = await axios({
                method: method,
                url: endpoint,
                data: formData,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('access_token')}`
                },
            });
    
            console.log(response);
            localStorage.setItem('informations', JSON.stringify(response.data));
            toast.success("Teacher information submitted successfully!");

            setTimeout(() => {
              props.getTeacherExp(true);
            },1000);
        } catch (err) {
          const errorMessage = err.response && err.response.data 
              ? `Error: ${err.response.status} - ${err.response.statusText}\n${JSON.stringify(err.response.data, null, 2)}`
              : "An unexpected error occurred. Please try again later.";
         toast.error(errorMessage);
        }
    };
    
      
    
    return (
<div className="w-full flex items-center justify-center pt-5">
  <div className="w-full max-w-3xl">
    <div className="mb-5 flex items-center justify-between">
      <h3 className="text-2xl font-bold" style={{ color: '#303972' }}>
        Employment Information
      </h3>
    </div>

    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Row 1 */}
        <div>
          <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="designation">
            Designation *
          </label>
          <select
            className="w-full h-12 border border-gray-300 rounded px-3 mt-1"
            value={formData.designation}
            onChange={handleSelectChange('designation')}
            name="designation"
          >
            <option value="">Select</option>
            <option value="teacher">Teacher</option>
            <option value="school Counselor">School Counselor</option>
            <option value="school Nurse">School Nurse</option>
            <option value="administrative">Administrative</option>
            <option value="janitor/Custodian">Janitor/Custodian</option>
            <option value="curriculum">Curriculum</option>
            <option value="coach">Coach</option>
            <option value="registrar">Registrar</option>
          </select>
          {errors.designation && (
            <p className="text-red-500 text-sm">{errors.designation}</p>)}
        </div>
        <div>
          <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="date_of_joining">
            Date Of Joining
          </label>
          <input
            type="date"
            name="date_of_joining"
            value={formData.date_of_joining}
            onChange={handleChange}
            className="w-full h-12 border border-gray-300 rounded px-2 mt-1"
          />
        </div>
        <div>
          <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="class_teacher">
            Class Teacher * 
          </label>
          <select
            className="w-full h-12 border border-gray-300 rounded px-3 mt-1"
            name="class_teacher"
            value={formData.class_teacher}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
          {errors.class_teacher && (
<p className="text-red-500 text-sm">{errors.class_teacher}</p>
)}
        </div>

        <div>
          <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="grade">
            Select Grade 
          </label>
          <select
            className="w-full h-12 border border-gray-300 rounded px-3 mt-1"
            value={formData.grade}
            onChange={handleSelectChange('grade')}
            name="grade"
            disabled={formData.class_teacher === "false" || !formData.class_teacher}
          >
            <option value="">Select Grade</option>
            <option value="play">Play School</option>
            <option value="nursery">Nursery</option>
            <option value="lkg">LKG</option>
            <option value="ukg">UKG</option>
            <option value="1">I Grade</option>
            <option value="2">II Grade</option>
            <option value="3">III Grade</option>
            <option value="4">IV Grade</option>
            <option value="5">V Grade</option>
            <option value="6">VI Grade</option>
            <option value="7">VII Grade</option>
            <option value="8">VIII Grade</option>
            <option value="9">IX Grade</option>
            <option value="10">X Grade</option>
          </select>
          {errors.grade && (
<p className="text-red-500 text-sm">{errors.grade}</p>
)}
        </div>
        {/* Row 2 */}
        <div>
          <label className="font-poppins text-lg font-semibold text-[#303972]" htmlFor="section">
            Select Section 
          </label>
          <select
            className="w-full h-12 border border-gray-300 rounded px-3 mt-1"
            value={formData.section}
            onChange={handleSelectChange('section')}
            name="section"
            disabled={formData.class_teacher === "false" || !formData.class_teacher}
          >
            <option value="">Select Section</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
            <option value="E">E</option>
            <option value="F">F</option>
            <option value="G">G</option>
            <option value="H">H</option>
            <option value="I">I</option>
            <option value="J">J</option>
            <option value="K">K</option>
            <option value="L">L</option>
            <option value="M">M</option>
            <option value="N">N</option>
            <option value="O">O</option>
            <option value="P">P</option>
            <option value="Q">Q</option>
            <option value="R">R</option>
            <option value="S">S</option>
            <option value="T">T</option>
            <option value="U">U</option>
            <option value="V">V</option>
            <option value="W">W</option>
            <option value="X">X</option>
            <option value="Y">Y</option>
            <option value="Z">Z</option>
          </select>
          {errors.section && (
<p className="text-red-500 text-sm">{errors.section}</p>
)}
        </div>

      </div>

      <div className="p-1 mt-5">
        <h2 className="font-bold text-2xl mb-5" style={{ color: '#303972' }}>Responsibility</h2>
        <table className="w-full border-collapse border border-gray-300">
          <tbody>
            {responsibilities.map(responsibility => (
              <tr key={responsibility.id}>
                <td className="border border-gray-300 p-2">{responsibility.grade}</td>
                <td className="border border-gray-300 p-2">{responsibility.section}</td>
                <td className="border border-gray-300 p-2">{responsibility.subject}</td>
                <td className="border border-gray-300 p-2 flex justify-center">
                  <button onClick={() => handleEdit(responsibility.id)} className="mr-2">
                    <FaEdit />
                  </button>
                  <button onClick={() => handleDelete(responsibility.id)}>
                                    <FaTrashAlt />
                  </button>
                </td>
              </tr>
            ))}
            <tr>
              <td className="border border-gray-300 p-2">
                <select
                  value={newResponsibility.grade}
                  onChange={(e) => setNewResponsibility({ ...newResponsibility, grade: e.target.value })}
                  className="w-full"
                  name='grade'
                >
                <option value="">Select Grade</option>
                <option value="play">Play School</option>
                <option value="nursery">Nursery</option>
                <option value="lkg">LKG</option>
                <option value="ukg">UKG</option>
                <option value="1">I Grade</option>
                <option value="2">II Grade</option>
                <option value="3">III Grade</option>
                <option value="4">IV Grade</option>
                <option value="5">V Grade</option>
                <option value="6">VI Grade</option>
                <option value="7">VII Grade</option>
                <option value="8">VIII Grade</option>
                <option value="9">IX Grade</option>
                <option value="10">X Grade</option>
                </select>
              </td>
              <td className="border border-gray-300 p-2">
                <select
                  value={newResponsibility.section}
                  onChange={(e) => setNewResponsibility({ ...newResponsibility, section: e.target.value })}
                  className="w-full"
                  name='section'
                >
                <option value="">Select Section</option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
                <option value="E">E</option>
                <option value="F">F</option>
                <option value="G">G</option>
                <option value="H">H</option>
                <option value="I">I</option>
                <option value="J">J</option>
                <option value="K">K</option>
                <option value="L">L</option>
                <option value="M">M</option>
                <option value="N">N</option>
                <option value="O">O</option>
                <option value="P">P</option>
                <option value="Q">Q</option>
                <option value="R">R</option>
                <option value="S">S</option>
                <option value="T">T</option>
                <option value="U">U</option>
                <option value="V">V</option>
                <option value="W">W</option>
                <option value="X">X</option>
                <option value="Y">Y</option>
                <option value="Z">Z</option>
                </select>
              </td>
              <td className="border border-gray-300 p-2">
                <select
                  value={newResponsibility.subject}
                  onChange={(e) => setNewResponsibility({ ...newResponsibility, subject: e.target.value })}
                  className="w-full"
                  name='subject'
                >
                <option value="">Select Subject</option>
                <option value="telugu">Telugu</option>
                <option value="hindi">Hindi</option>
                <option value="english">English</option>
                <option value="mathematics">Mathematics</option>
                <option value="science">Science</option>
                <option value="social">Social</option>
                <option value="physics">Physics</option>
                <option value="art">Art</option>
                </select>
              </td>
              <td className="border border-gray-300 p-2 flex justify-center">
                <button
                  type="button"
                  onClick={() => setNewResponsibility()}
                  className="mr-2 text-red-500"
                >
                  <FaTimesCircle />
                </button>
                <button
                  type="button"
                  onClick={addResponsibility}
                  className="text-green-500"
                >
                  <FaCheckCircle />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="flex justify-end mt-5">
        <div className="button-container">
          <button
            type="button"
            className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
            onClick={() => props.getTeacher(true)}
          >
            Back
          </button>
          <button
            type="submit"
            className="bg-[#EF805D] text-white px-4 py-2 rounded"
          >
            Next
          </button>
        </div>
      </div>
    </form>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
  />

  </div>
</div>
    );
};

export default TeacherForm;
