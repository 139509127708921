import React, { useEffect, useState, useCallback } from 'react';
import './behaviour.scss';
import { Select, Option, Card, Avatar, Spinner } from '@material-tailwind/react';
import BehaviourRightSec from './BehaviourRightSec';
import { useParams, useLocation } from 'react-router-dom';
import { getApiResponse } from '../../APIs/getApiResponse';
import { endpoints } from '../../APIs/endpoints';
import { apiBaseUrl } from "../../APIs/endpoints";
import DatePicker from '../../commonComp/DateRangePicker';
import { handleError } from '../../APIs/apiUtils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const Index = () => {
    const params = useParams();
    const location = useLocation();
    const classes = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
    const getClassFromParam = classes.indexOf(params?.class);
    const [singleClassData, setSingleClassData] = useState({});
    const [selectedClassValue, setSelectedClassValue] = useState(classes[getClassFromParam] || '');
    const [dateRange, setDateRange] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState('');
    const [isRequestActive, setIsRequestActive] = useState(false);
    const [shouldFetch, setShouldFetch] = useState(false); 

    const navigate = useNavigate();
    
    useEffect(() => {
        const { state } = location;
        if (state) {
            if (state.fromDate && state.toDate) {
                setDateRange({
                    startDate: state.fromDate,
                    endDate: state.toDate
                });
            }
            if (state.class) {
                setSelectedClassValue(state.class);
            }
            if (state.subject) {
                setSelectedSubject(state.subject);
            }
        }
    }, [location]);

    const getBehaviourData = useCallback(async (val = '') => {
        if (isRequestActive) return;
        if (!dateRange.startDate || !dateRange.endDate || !selectedSubject) return; 
        setIsLoading(true);
        setIsRequestActive(true); 
        try {
            let urlParams = new URLSearchParams({
                class: val || selectedClassValue || params?.class || '1'
            });

            urlParams.append('fromDate', dateRange.startDate);
            urlParams.append('toDate', dateRange.endDate);
            urlParams.append('subject', selectedSubject);
        
            const resp = await getApiResponse(endpoints.BEHAVIOURAL + '?' + urlParams);
            setSingleClassData(resp?.data || {});
        } catch (error) {
            const { errorMessage } = handleError(error);
            navigate('/error', { 
                state: { errorMessage }
            });
        } finally {
            setIsLoading(false);
            setIsRequestActive(false);
        }
    }, [params?.class, dateRange, navigate, selectedClassValue, selectedSubject, isRequestActive]);
    

    const getSelectedClass = useCallback((val) => {
        setSelectedClassValue(val);
        setShouldFetch(true);
    }, [getBehaviourData]);

    const handleSubjectChange = useCallback((subject) => {
        setSelectedSubject(subject);
    }, [getBehaviourData]);

    useEffect(() => {
        if (shouldFetch && dateRange.startDate && dateRange.endDate && selectedSubject) {
            getBehaviourData();
            setShouldFetch(false); 
        }
    }, [getBehaviourData, shouldFetch, dateRange, selectedSubject]);

    const getDateRange = useCallback((range) => {
        setDateRange(range);
        setShouldFetch(true); 
    }, [getBehaviourData]);

    return (
        <div className='behaviour-container'>
            <ToastContainer />
            <div className="behaviour-left-section">
                <Card className='card-section w-full'>
                    <div className='class-selection'>
                        <div 
                            className='date-class-container flex flex-col md:flex-row items-center md:space-x-4 space-y-4 md:space-y-0 w-full' 
                            style={{ marginTop: '-1rem', boxSizing: 'border-box' }}
                        >
                            <div className='search-date w-full md:w-1/2'>
                                <DatePicker 
                                    getDateRange={getDateRange}
                                    initialDateRange={dateRange}
                                    initiallySelected={true}
                                />
                            </div>
                            <div className='w-full md:w-1/2'>
                                <Select
                                    label="Select Class"
                                    value={selectedClassValue}
                                    onChange={(val) => getSelectedClass(val)}
                                >
                                    {
                                        classes.map(cls => (
                                        <Option key={cls} value={cls}>{cls}</Option>))
                                    }
                                </Select>
                            </div>
                        </div>
                        {isLoading ? (
                            <div className="flex justify-center items-center h-64">
                                <Spinner className="h-12 w-12" color="blue" />
                            </div>
                        ) : (
                            <>
                                <div className='class-info-section'>
                                    <div className='class-details'>
                                        <div className='details-container'>
                                            <div className='value'>{singleClassData?.meta?.class}</div>
                                            <div className='title'>class</div>
                                        </div>
                                        <div className='details-container'>
                                            <div className='value'>{singleClassData?.meta?.total_students}</div>
                                            <div className='title'>Total Students</div>
                                        </div>
                                        <div className='details-container'>
                                        <div className='value'>{selectedSubject || singleClassData?.meta?.subject}</div>
                                        <div className='title'>Subject:</div>
                                        </div>
                                    </div>
                                    <div className='class-info-image'>
                                        <img src="https://img.freepik.com/free-vector/online-education-concept_23-2148158795.jpg" alt="Class Info" />
                                    </div>
                                </div>
                                <div className='section-divider'></div>
                                <div className='class-sections-container'>
                                    {
                                        singleClassData?.meta?.sections.map((sec, i) => {
                                            const { section, attended, total, class_teacher,class_teacher_name } = sec;
                                            const bgColor = ['#FCDBD1', '#D1DDFC', '#D1FCE2'];
                                            return <Card key={section} className='section-card' style={{ background: bgColor[i] }}>
                                                <div className='section-header'>
                                                    <div className='section-title'>Section {section}</div>
                                                </div>
                                                <div className='class-details'>
                                                    <div className='class-strength'>
                                                        <div className='attendance'>
                                                            <span>{attended}</span>
                                                            {total && <span>/{total}</span>}
                                                        </div>
                                                        <div className='title'>Total Attendance</div>
                                                    </div>
                                                    <div className='class-teacher'>
                                                        <div className='teacher-img'><Avatar src={`${apiBaseUrl}${class_teacher}`} alt="Class Teacher" /></div>
                                                        <div className='teacher-name'>{class_teacher_name}</div>
                                                    </div>
                                                </div>
                                            </Card>
                                        })
                                    }
                                </div>
                            </>
                        )}
                    </div>
                </Card>
            </div>
            <div className="behaviour-right-section">
                {dateRange.startDate && dateRange.endDate ? (
                    <BehaviourRightSec 
                        data={singleClassData?.data || {}} 
                        onSubjectChange={handleSubjectChange}
                        selectedSubject={selectedSubject}
                        fromDate={dateRange.startDate}
                        toDate={dateRange.endDate}
                        classInfo={selectedClassValue}
                    />
                ) : (
                    <div>Please select a date range to view the data.</div>
                )}
            </div>

        </div>
    )
}

export default Index;