import React, { useEffect, useState, useCallback } from "react";
import './attendance.scss';
import { Avatar, Tooltip } from "@material-tailwind/react";
import { getApiResponse } from "../../APIs/getApiResponse";
import { endpoints } from "../../APIs/endpoints";
import { HiOutlineDesktopComputer, HiOutlineUsers } from "react-icons/hi";
import { FiUserCheck } from "react-icons/fi";
import Pagination from '../../commonComp/Pagination';
import { apiBaseUrl } from "../../APIs/endpoints";
import { useNavigate } from "react-router-dom";

const Index = () => {
    const formatDate = (date) => {
        const d = new Date(date);
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d.getDate().toString().padStart(2, '0');
        const year = d.getFullYear();
       // return `${month}/${day}/${year}`;
        return `${day}/${month}/${year}`;
    };

const getYesterdayDate = () => {
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    return formatDate(yesterday);
};

    const [attendanceData, setAttendanceData] = useState({});
    const [classesList, setClassesList] = useState(['All']);
    const [sectionsList, setSectionsList] = useState([]);
    const [subjectsList, setSubjectsList] = useState(['All']); 
    const [selectedDate, setSelectedDate] = useState(getYesterdayDate());

    const [filterObj, setFilterObj] = useState({
        className: 'All',
        subject: 'All',
        date: selectedDate 
    });

    const [studentsList, setStudentsList] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('access_token'); 
    
        if (!token) { 
          navigate('/login'); 
        }
      }, [navigate]); 
    
    const getAttendance = async () => {
        let attendanceUrl = endpoints.ATTENDANCE;
        if(Object.keys(filterObj).length) {
            const { date, className, section, subject } = filterObj; 
            let params = '';

            if (date) {
                params = `date=${date}&`;
            }
            if(className && className !== 'All') {
                params = params.length ? params + `class=${className}&` : `class=${className}&`;
            }
            if(section) {
                params = params.length ? params + `section=${section}&` : `section=${section}&`;
            }
            if(subject && subject !== 'All') {
                params = params.length ? params + `subject=${subject}&` : `subject=${subject}&`;
            }
            attendanceUrl = attendanceUrl + '?' + params.slice(0, -1);
        };
        try {
            const resp = await getApiResponse(attendanceUrl);
            setAttendanceData(resp?.data || {
                attendance: { value: 0, increased: false, change: 0 },
                absence: { value: 0, increased: false, change: 0 },
                late_comers: { value: 0, increased: false, change: 0 },
                students: []
            });

            if(resp?.data?.classes){
                setClassesList(['All', ...Object.keys(resp?.data?.classes)]);
            }

            if(resp?.data?.subjects){
                setSubjectsList(['All', ...resp?.data?.subjects]);
            }
        } catch (error) {
            console.error("Error fetching attendance data:", error);
            setAttendanceData({
                attendance: { value: 0, increased: false, change: 0 },
                absence: { value: 0, increased: false, change: 0 },
                late_comers: { value: 0, increased: false, change: 0 },
                students: []
            });
        }
    }

    useEffect(() => {
        getAttendance();
    }, [filterObj])

    const getDate = useCallback((date) => {
        const formattedDate = new Date(date).toLocaleDateString('en-GB'); // en-GB formats to dd/mm/yyyy
        
        setSelectedDate(formattedDate);
        setFilterObj(prev => ({
            ...prev,
            date: formattedDate 
        }));
    }, []);
    

    const getSelectedClass = e => {
        const val = e.target.value;
        setFilterObj((prev) => ({...prev, className: val, section: ''}));
        if (val !== 'All') {
            setSectionsList(attendanceData?.classes?.[val] || []);
        } else {
            setSectionsList([]);
        }
    }

    const getSelectedSection = e => {
        setFilterObj((prev) => ({...prev, section: e.target.value}));
    }

    const getSelectedSubject = e => {
        setFilterObj((prev) => ({...prev, subject: e.target.value}));
    }

    const getRecords = data => {
        setStudentsList(data);
    };

    const valueMap = {P: 'Present', A: 'Absent', NotRecorded: 'Absent'}

    const arrowMark = (increased = true) => <span className={increased ? 'upArrow' : 'downArrow'}>&#x279D;</span>;
    return (
        <div className="attendance-container">
            <h3 className="top-heading">Attendance</h3>
            <div className="filter-section" >
            <div className="filter-section-datefield">
                <input
                    type="date"
                    value={selectedDate.split('/').reverse().join('-')}
                    onChange={(e) => getDate(e.target.value)}
                    className="border-2 border-gray-300 rounded-md p-2 focus:border-[#EF805D] focus:outline-none 
                            bg-white text-gray-700 placeholder-gray-400 transition duration-200 
                            hover:shadow-lg hover:border-[#EF805D]"
                    placeholder="Select a date"
                />
                </div>
                <div className="filter-section-dropdown">
                    <select
                     className="dropdown-select border-none"
                     value={filterObj.className}
                     onChange={getSelectedClass}>
                        <option disabled>Select Class</option>
                        {classesList.map( (cls, i) => <option key={cls+i} value={cls}>{cls}</option>)}
                    </select>
                </div>
                <div className="filter-section-dropdown">
                    <select
                     className="dropdown-select border-none"
                     value={filterObj.section || ''}
                     onChange={getSelectedSection}>
                        <option disabled value=''>Select Section</option>
                        {sectionsList?.map((sec, i) => <option key={sec+i} value={sec}>{sec}</option>)}
                    </select>
                </div>
                <div className="filter-section-dropdown">
                    <select
                     className="dropdown-select border-none"
                     value={filterObj.subject}
                     onChange={getSelectedSubject}>
                        <option disabled>Select Subject</option>
                        {/* <option value="All">All</option> */}
                        <option value="science">Science</option>
                        <option value="social">Social</option>
                        <option value="maths">Mathematics</option>
                        <option value="telugu">Telugu</option>
                        <option value="hindi">Hindi</option>
                        <option value="english">English</option>
                        <option value="art">Art</option>
                        <option value="music">Music</option>
                    </select>
                </div>
            </div>
            <div className="attendance-status-container">
                <div className="attendance-content">
                    <div className="section-content">
                        <div className="section-icon">
                            <HiOutlineUsers size='50'/>
                        </div>
                        <div className="section-data">
                            <div className="section-title">Total Attendance</div>
                            <div className="section-score">{attendanceData?.attendance?.value}%</div>
                            <div className="section-growth">
                                {arrowMark(attendanceData?.attendance?.increased)}
                                <span>{attendanceData?.attendance?.change}%</span> this month
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="section-icon">
                            <FiUserCheck size='50'/> 
                        </div>
                        <div className="section-data">
                            <div className="section-title">Total Absence</div>
                            <div className="section-score">{attendanceData?.absence?.value}%</div>
                            <div className="section-growth">
                                {arrowMark(attendanceData?.absence?.increased)}
                                <span>{attendanceData?.absence?.change}%</span> this month
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="section-icon">
                            <HiOutlineDesktopComputer size="50" />
                        </div>
                        <div className="section-data">
                            <div className="section-title">Late Comers</div>
                            <div className="section-score">{attendanceData.late_comers?.value}</div>
                            <div className="section-growth">
                                {arrowMark(attendanceData?.late_comers?.increased)}
                                <span>{attendanceData.late_comers?.change}%</span> this month
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="attendance-list-section" >
                <h4 className="attendance-list-section-heading">All Students</h4>
                <table className="attendance-table-content" >
                    <thead className="attendance-table-header">
                        <tr className="table-row">
                            <th>Student Name</th>
                            <th><div className="header-content">
                                    <div className="header-icon">
                                        <img src="/images/list_check.png" alt="icon"/>
                                    </div>
                                    <div className="header-text">Manual Attendance</div>
                                </div>
                            </th>
                            <th>
                                <div className="header-content">
                                    <div className="header-icon">
                                        <img src="/images/cc_camera.png" alt="icon"/>
                                    </div>
                                    <div className="header-text">Digital Attendance</div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {studentsList?.map(stu => {
                            return <tr className="attendance-table-row" key={stu.id}>
                                <td>
                                    <div className="user-name">
                                        <span className="user-icon">
                                            <Tooltip content={<img src={`${apiBaseUrl}${stu?.student_photo}`} alt="student" style={{maxWidth: '400px', maxHeight: '400px', }} />} placement="right-end">
                                                <Avatar src={`${apiBaseUrl}${stu?.student_photo}`} alt="student-icon" />
                                            </Tooltip>
                                        </span>
                                        <span>{stu.name}</span>
                                    </div>
                                </td>
                                <td>
                                    <span className="manual-attendance-line"></span>
                                </td>
                                <td>
                                    <span className={stu.attendance === 'P' ? 'stu-present' : stu.attendance === 'A' ? 'stu-absent' : 'stu-default'}>{valueMap[stu.attendance]}</span>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
                {attendanceData?.students?.length > 0 && <Pagination data={attendanceData?.students} getRecords={getRecords} itemsPerPage={10} />}
            </div>            
        </div>
    )
}

export default Index;
